/**
 * Takes an address object and formats it into a string
 * Example address obj
 * {
 *   address:"11th Street 10"
 *   address_2:"Miami-Dade County"
 *   address_id:"13d60351"
 *   city:"Miami Beach"
 *   default_address:false
 *   region:"southeast"
 *   state:"FL"
 *   zip:"33139"
 * }
 *
 * @param addr
 * @returns String
 */
export default function address(addr) {
  // console.log("address filter ", addr);
  if (!addr) return null

  const address = addr?.address
  const address2 = addr?.address_2
  const city = addr?.city
  const state = addr?.state
  const zip = addr?.zip

  // console.log(address, address2, city, state, zip);

  if (address && city && state && zip) {
    // Example: 12th Street 15, Miami-Dade County, Miami Beach, FL 33139
    return `${address}, ${address2 ? `${address2}, ` : ''}${city}, ${state} ${zip}`
  }

  return null
}
